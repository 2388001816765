<template>
	<div class="container-right">
		<div v-if="tabIdx=='tab1'">
			<div class="pageRNenter" v-if="!obligeeType">
				<div class="container-right-name">
					实名认证
					<div class="line"></div>
				</div>
				<div class="block_tab">
					<div class="tab">
						<div class="ant-btn ant-btn-primary" @click="tabIdx='tab2'">
							个人身份认证
						</div>
					</div>
					<!-- <div class="tab">
						<div class="ant-btn ant-btn-primary" @click="tabIdx='tab3'">
							企业身份认证
						</div>
					</div> -->
					<div class="clear"></div>
				</div>

				<div class="blockReminder">
					<div class="h3">上传证件要求：</div>
					<div class="description" slot="description">
						1、每个账号仅可关联一个实名个人或一个企业；<br>
						2、个人认证：需要提交个人身份证复印件进行确认您的身份，用于确权、版权登记等平台业务的授权；<br>
						3、企业认证：您需要提交企业营业执照、企业授权书；<br>
					</div>
				</div>
			</div>
			<div class="resultPersonal" v-if="obligeeType=='user'">
				<div class="container-right-name">
					实名认证
					<div class="line"></div>
				</div>
				<div class="MainStepsForm">
					<div class="formWrap">
						<el-form ref="form" label-width="140px">
							<el-form-item label="姓名" prop="userName">
								<el-input v-model="userAuthInfo.userName" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="证件类型" prop="identityType">
								<el-select v-model="userAuthInfo.identityName" :disabled="true">
								</el-select>
							</el-form-item>
							<el-form-item label="证件号" prop="uniqueCode">
								<el-input v-model="userAuthInfo.uniqueCode" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="证件有效时间" prop="startDate">
								<el-col :span="11">
									<el-date-picker type="date" placeholder="开始日期" format="yyyy-MM-dd" value-format="timestamp" v-model="startDateText" :disabled="true"
									style="width: 100%;"></el-date-picker>
								</el-col>
								<el-col :span="2" v-if="this.userAuthInfo.longValid == '2'">-</el-col>
								<el-col :span="11" v-if="this.userAuthInfo.longValid == '2'">
									<el-date-picker type="date" placeholder="截止日期" format="yyyy-MM-dd" value-format="timestamp" v-model="expireDateText" :disabled="true"
									style="width: 100%;"></el-date-picker>
								</el-col>
							</el-form-item>
							<el-form-item label="国家省市" prop="province">
								<el-col :span="11">
									<el-input v-model="userAuthInfo.province" :disabled="true"></el-input>
								</el-col>
								<el-col :span="2">-</el-col>
								<el-col :span="11">
									<el-input v-model="userAuthInfo.city" :disabled="true"></el-input>
								</el-col>
							</el-form-item>
							<el-form-item label="身份证" prop="additionalProp1">
								<div class="uploadIDcard">
									<el-upload class="Uploader fl" action="" :disabled="true">
										<img v-if="userAuthInfo.identityPicture.additionalProp1" :src="userAuthInfo.identityPicture.additionalProp1" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"><p>上传人像面</p></i>
									</el-upload>
									<el-upload class="Uploader fl" action="" :disabled="true">
										<img v-if="userAuthInfo.identityPicture.additionalProp2" :src="userAuthInfo.identityPicture.additionalProp2" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"><p>上传国徽面</p></i>
									</el-upload>
									<el-upload class="Uploader fl" action="" :disabled="true">
										<img v-if="userAuthInfo.identityPicture.additionalProp3" :src="userAuthInfo.identityPicture.additionalProp3" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"><p>上传手持证件人照片</p></i>
									</el-upload>
									<div class="clear"></div>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
			<div class="resultCompany" v-if="obligeeType=='company'">
				<div class="container-right-name">
					实名认证
					<div class="line"></div>
				</div>
				<div class="MainStepsForm">
					<div class="formWrap">
						<el-form ref="form" :model="form" label-width="180px">
							<el-form-item label="企业名称" prop="companyName">
								<el-input v-model="userAuthInfo.companyName" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="证件类型" prop="identityType">
								<el-select v-model="userAuthInfo.identityName" :disabled="true">
								</el-select>
							</el-form-item>
							<el-form-item label="证件号" prop="uniqueCode">
								<el-input v-model="userAuthInfo.uniqueCode" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="证件有效时间" prop="startDate">
								<el-col :span="11">
									<el-date-picker type="date" placeholder="开始日期" format="yyyy-MM-dd" value-format="timestamp" v-model="startDateText" :disabled="true"
									style="width: 100%;"></el-date-picker>
								</el-col>
								<el-col :span="2" v-if="this.userAuthInfo.longValid == '2'">-</el-col>
								<el-col :span="11" v-if="this.userAuthInfo.longValid == '2'">
									<el-date-picker type="date" placeholder="截止日期" format="yyyy-MM-dd" value-format="timestamp" v-model="expireDateText" :disabled="true"
									style="width: 100%;"></el-date-picker>
								</el-col>
							</el-form-item>
							<el-form-item label="国家省市" prop="province">
								<el-col :span="11">
									<el-input v-model="userAuthInfo.province" :disabled="true"></el-input>
								</el-col>
								<el-col :span="2">-</el-col>
								<el-col :span="11">
									<el-input v-model="userAuthInfo.city" :disabled="true"></el-input>
								</el-col>
							</el-form-item>
							<el-form-item label="证件照片" prop="additionalProp1">
								<div class="uploadBusinessLicense">
									<el-upload class="Uploader fl" action="" :disabled="true">
										<img v-if="userAuthInfo.identityPicture.additionalProp1" :src="userAuthInfo.identityPicture.additionalProp1" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>
									<div class="clear"></div>
								</div>
							</el-form-item>
							<el-form-item label="证明材料" prop="additionalProp2">
								<div class="uploadBusinessLicense">
									<el-upload class="Uploader2 fl" action="" :disabled="true">
										<img v-if="userAuthInfo.identityPicture.additionalProp2" :src="userAuthInfo.identityPicture.additionalProp2" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>
									<div class="clear"></div>
								</div>
							</el-form-item>

						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div v-if="tabIdx=='tab2'">
			<personal @set-tab-idx="setTabIdx"></personal>
		</div>
		<div v-if="tabIdx=='tab3'">
			<company @set-tab-idx="setTabIdx"></company>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import Company from './components/Company.vue'
	import Personal from './components/Personal'
	import {
		queryObligeeList
	} from '@/api/ObligeeService'
	export default {
		name: 'RealName',
		components: {
			Company,
			Personal
		},
		computed: {
			...mapGetters(['mid', 'userInfo', 'authInfo'])
		},
		data() {
			return {
				form: {},
				tabIdx: 'tab1',
				obligeeType: null,
				userAuthInfo: null
			}
		},
		created() {
			this.getAuthInfo();
		},

		methods: {
			setTabIdx(tabIdx) {
				this.tabIdx = tabIdx
			},
			getAuthInfo() {
				let that = this;
				if (this.authInfo) {
					that.setAuthInfo(this.authInfo);
				} else {
					//查询是否实名认证
					queryObligeeList(1, 100).then(({data}) => {
						// console.log('--------',data.list.length);
						if (data && data.list.length > 0) {
							that.$store.commit('setAuthInfo', data.list[0]);
							that.setAuthInfo(data.list[0]);
						}

					})
				}
			},
			setAuthInfo(authInfo) {
				this.userAuthInfo = authInfo;
				console.log("999999",authInfo);
				if (authInfo.obligeeType == '1') {
					this.obligeeType = 'user';
				} else {
					this.obligeeType = 'company';
				}

				if(authInfo.identityType == '1'){
					this.userAuthInfo.identityName = '身份证'
				}else if(authInfo.identityType == '2'){
					this.userAuthInfo.identityName = '营业执照'
				}else if(authInfo.identityType == '3'){
					this.userAuthInfo.identityName = '企业法人营业执照'
				}else if(authInfo.identityType == '4'){
					this.userAuthInfo.identityName = '组织机构代码证书'
				}else if(authInfo.identityType == '5'){
					this.userAuthInfo.identityName = '事业单位法人证书'
				}else if(authInfo.identityType == '6'){
					this.userAuthInfo.identityName = '社团法人证书'
				}else if(authInfo.identityType == '12'){
					this.userAuthInfo.identityName = '社会统一信用代码证'
				}else if(authInfo.identityType == '7'){
					this.userAuthInfo.identityName = '其他有效证件'
				}

				this.startDateText = authInfo.startDate * 1000;
				this.expireDateText = authInfo.expireDate * 1000;

			}
		}
	}
</script>

<style lang='scss' scoped>
	.container-right {
		padding: 0 32px 0;
	}

	.container-right-name {
		font-size: 24px;
		font-weight: 600;
		color: rgba(0, 18, 22, 0.85);
		line-height: 36px;
		padding: 32px 0;

		.line {
			width: 100%;
			height: 2px;
			margin-top: 24px;
			background: rgba(0, 0, 0, 0.02);
		}
	}

	.blockReminder {
		width: 664px;
		background: rgba(255, 231, 186, 0.2);
		border: 1px solid #FFD591;
		padding: 16px;

		.h3 {
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			margin-bottom: 8px;
		}

		.description {
			font-size: 12px;
			font-weight: 400;
			color: rgba(0, 18, 22, 0.5);
		}
	}

	.clear {
		clear: both;
		display: block;
	}

	.pageRNenter {
		.block_tab {
			margin-bottom: 48px;

			.tab {
				float: left;
				width: 320px;
				height: 160px;
				background: #F7F9FA;
				text-align: center;
			}

			.tab:nth-child(2) {
				margin-left: 24px;
			}

			.ant-btn {
				margin: 64px auto;
				line-height: 30px;
				box-sizing: border-box;
			}
		}
	}

	.resultPersonal {
		.resultwrap {
			margin-bottom: 16px;
			padding: 24px 24px 24px 80px;
			box-sizing: border-box;
			background: #F5FDFD;
			border-radius: 2px;
			border: 1px solid #D3DCE6;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			line-height: 24px;

			.icon {
				color: #0A4FD1;
				font-size: 40px;
				position: absolute;
				top: 24px;
				left: 24px;
			}
		}

		.IDcardwrap {
			.IDcard {
				width: 200px;
				height: 140px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				display: inline;
				overflow: hidden;
				position: relative;
			}

			.IDcard:first-child {
				margin-right: 16px;
			}

			.IDcard img{
				width: 100%;
				height: auto;
				display: block;
				position: absolute;
				top:50%; 
				left:50%;
				transform: translate(-50%,-50%);
			}
		}
	}

	.resultCompany {
		.resultwrap {
			margin-bottom: 16px;
			padding: 24px 24px 24px 80px;
			box-sizing: border-box;
			background: #F5FDFD;
			border-radius: 2px;
			border: 1px solid #D3DCE6;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			color: rgba(0, 18, 22, 0.65);
			line-height: 24px;

			.icon {
				color: #0A4FD1;
				font-size: 40px;
				position: absolute;
				top: 24px;
				left: 24px;
			}
		}

		.BusinessLicensewrap {
			.BusinessLicense {
				width: 132px;
				height: 180px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				overflow: hidden;
				position: relative;
				img{
					width: 100%;
					height: auto;
					display: block;
					position: absolute;
					top:50%; 
					left:50%;
					transform: translate(-50%,-50%);
				}
			}

			.IDcard:first-child {
				margin-right: 16px;
			}
		}

	}
	.MainStepsForm{
		padding-bottom: 32px;
		width: 568px;
	}
	.el-col-2{text-align: center;}
	.uploadIDcard{
		white-space: nowrap;
	}
	.Uploader,.Uploader2{
		width: 182px;
				height: 140px;
				display: inline-block;
				margin-right: 10px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				position: relative;
	}
	.Uploader img,.Uploader2 img{
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 100%;
			display: block;
			position: absolute;
			top:50%; 
			left:50%;
			transform: translate(-50%,-50%);
	}


	@media screen and (max-width: 991px){
		.container-right{padding: 0 20px 20px;}
		.MainStepsForm{width: 100%;}
		/deep/.el-form-item__label{width: 100px!important;}
		/deep/.el-form-item__content{margin-left: 100px!important;}
		/deep/.el-input--suffix .el-input__inner{padding-right: 10px;}
		.Uploader, .Uploader2{display: block;margin-bottom: 10px;}

		.blockReminder{width: 100%;}
	}
</style>
